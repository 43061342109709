import React, {Component} from 'react';
import {
  TextField,
  Button
} from '@material-ui/core';
import { ArrowForward } from "@material-ui/icons";

class ContactForm extends Component {

  render() {
    return (
        <>
          <div style={{padding: "5px", background: "white", flex: "1 0 320px"}}>
            <h3>Contact us below</h3>
            <form action="https://formspree.io/mvoaygar" method="POST">
              <div
                  style={{margin: "10px 0"}}
              >
                <TextField
                    autoComplete="true"
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Email Address"
                    label="Email"
                    variant="outlined"
                    required={true}
                    style={{width: "90%", maxWidth: "100%"}}/>
              </div>
              <div
                  style={{margin: "10px 0"}}
              >
                <TextField autoComplete="true"
                           name="name" id="name"
                           label="Name"
                           placeholder="Name"
                           variant="outlined"
                           required={true}
                           style={{width: "90%", maxWidth: "100%"}}/>
              </div>
              <div
                  style={{margin: "10px 0"}}
              >
                <TextField autoComplete="true"
                           name="phone" id="phone"
                           label="Phone"
                           placeholder="Phone #"
                           variant="outlined"
                           type="tel"
                           required={true}
                           style={{width: "90%", maxWidth: "100%"}}/>
              </div>
              <div
                  style={{margin: "10px 0"}}
              >
                <TextField name="message"
                           id="message"
                           placeholder="Your message"
                           label="Message"
                           multiline={true}
                           required={true}
                           rows="5"
                           variant="outlined"
                           style={{width: "90%", maxWidth: "100%"}}/>
              </div>
              <Button variant="contained" type="submit">Send Message <ArrowForward style={{width: `30px`}}/></Button>
            </form>
          </div>
        </>
    );
  }
}

export default ContactForm;
