import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm";
import Image from "../components/image";

const IndexPage = () => {
  return (
  <Layout>
    <SEO title="Home | Epic Floral Co." />
    <h1>Wedding and Special Event Floral</h1>
    <p>More information coming soon!</p>
      <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap-reverse", alignItems: "space-between"}}>
          <ContactForm />
          <Image />
      </div>
  </Layout>
)};

export default IndexPage
